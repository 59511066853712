<template>
  <div class="main" v-loading="loading">
    <div class="top">
      <span>{{ lang.v.messageBoard }}</span>
      <div class="top-right" @click="onRefresh">
        <span>{{ countTime }}s</span>
        <img src="~@/assets/img/replease.png" alt="" />
      </div>
    </div>
    <el-divider />
    <div class="center_box">
      <div v-for="(item, index) in chatData" :key="index" class="card">
        <div class="card-top">
          <div class="card-top-left">
            <img src="~@/assets/img/Ellipse.png" alt="" class="avatar" />
            {{
              userinfo?.userId == item?.sellUser
                ? lang.v.you
                : lang.v.anonymousUser
            }}
          </div>
          <span>{{ item.createTime }}</span>
        </div>
        <div class="card-content">
          {{ item.content }}
        </div>
        <divider />
      </div>
    </div>
    <div class="bottom">
      <button @click="onPopup">{{ lang.v.reply }}</button>
    </div>
    <van-popup
      v-model:show="show"
      class="popup"
      :style="{ padding: '24px', borderRadius: '8px' }"
    >
      <h3 class="title">{{ lang.v.enterBelow }}</h3>
      <input type="text" v-model="inputValue" />
      <button @click="onSubmit">{{ lang.v.reply }}</button>
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, watch, defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { lang } from '@/lang';
import {
  fetchTransactionOrderChat,
  fetchTransactionOrderChatList,
} from '../../api/fetcher';
import dayjs from 'dayjs';
const show = ref(false);
const route = useRoute();
const countTime = ref(10);
const props = defineProps({
  orderData: {
    type: Object,
  },
});
const orderData = ref();
//watch 监听
watch(
  () => props?.orderData,
  newValue => {
    orderData.value = newValue;
    TransactionOrderChatList();
  },
  { deep: true }
);

// 获取用户信息
const userinfo = ref(JSON.parse(localStorage.getItem('userInfo')));
//talk
const inputValue = ref('');
//loading
const loading = ref(false);
//聊天消息
const chatData = ref();
let timer;
// 获取聊天历史记录
const TransactionOrderChatList = async () => {
  const data = await fetchTransactionOrderChatList({
    orderId: route.query?.orderId || orderData.value?.id,
  });
  if (data) {
    chatData.value = data.reduce((arr, item) => {
      item.createTime = dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss');
      arr.push(item);
      return arr;
    }, []);
  }
  chatData.value = data;
};

//打开输入框
const onPopup = () => {
  show.value = true;
};
const countdown = () => {
  countTime.value = 10;
  timer = setInterval(() => {
    countTime.value--;
    if (countTime.value < 0) {
      TransactionOrderChatList();
      countTime.value = 10;
    }
  }, 1000);
};

// 刷新
const onRefresh = async () => {
  TransactionOrderChatList();
  clearTimeout(timer);
  countdown();
};
//回复
const onSubmit = async () => {
  loading.value = true;
  try {
    await OrderChatTalk(inputValue.value);
    TransactionOrderChatList();
  } catch {
    console.log('err');
  }
  show.value = false;
  inputValue.value = '';
  loading.value = false;
};
const OrderChatTalk = async params => {
  const data = await fetchTransactionOrderChat({
    orderId: route.query?.orderId || orderData.value?.id,
    userId: userinfo.value?.userId,
    content: params,
  });
  return data;
};
onMounted(() => {
  if (props.orderData) {
    TransactionOrderChatList();
  }
  countdown();
});

onUnmounted(() => {
  clearTimeout(timer);
});
</script>

<style lang="less" scoped>
.main {
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 0.08rem;
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .top-right {
      font-size: 0.12rem;
      color: #858c9f;
      line-height: 0.16rem;
      img {
        margin-left: 0.1rem;
        width: 0.16rem;
        height: 0.16rem;
      }
    }
  }
  .center_box {
    .card {
      padding: 0.12rem 0;
      border-bottom: 1px dashed #f0f0f0;
      .card-top {
        margin-bottom: 0.14rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-top-left {
          display: flex;
          align-items: center;
          .avatar {
            margin-right: 0.05rem;
            width: 0.22rem;
            height: 0.22rem;
          }
        }
      }
    }
  }
  .card-content {
    word-wrap: break-word;
    min-height: 0.6rem;
  }
  .bottom {
    margin-top: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.title {
  font-weight: 600;
}
.popup {
  padding: 0.24rem;
  height: 2rem;
  border-radius: 0.08rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
button {
  color: #fff;
  background: #ed931e;
  border-radius: 0.04rem;
  width: 0.84rem;
  height: 0.24rem;
  line-height: 0.24rem;
  padding: 0;
  font-size: 0.12rem;
}
button:focus,
button:active:focus,
button.active:focus,
button.focus,
button:active.focus,
button.active.focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
}
</style>

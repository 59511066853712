<template>
  <div class="points-detailed">
    <div class="detailed-top">
      <div class="title">
        <div class="title-left">
          <span class="buy" v-if="type == 2">{{ lang.v.sellOut }}</span>
          <span class="sell" v-else>{{ lang.v.purchase }}</span>
          <span class="integral">{{ lang.v.integral }}</span>
        </div>
        <div class="title-right" v-if="transactionGoods.order">
          <span class="order-id">{{ transactionGoods.order.code }}</span>
        </div>
      </div>
      <div class="total-item">
        <span>{{ lang.v.totalAmount }}</span>
        <span class="number">$ {{ transactionGoods.amount }}</span>
      </div>
      <div class="total-item">
        <span>{{ lang.v.price }}</span>
        <span class="number"
        >${{
          (transactionGoods.amount / transactionGoods.integral).toFixed(2)
        }}</span
        >
      </div>
      <div class="total-item">
        <span>{{ lang.v.integral }}</span>
        <span class="number">{{ transactionGoods.integral }}</span>
      </div>
    </div>
    <div class="detailed-bottom">
      <div class="tips">
        <img src="~@/assets/img/attentionIcon.png" alt="" class="tips-icon" />
        <span>{{ lang.v.tradingPrecautions }}</span>
      </div>
      <div class="tips">
        <span>{{ lang.v.afterQuantity }}</span>
      </div>
      <div class="tips">
        <span>{{ lang.v.confirmCancelled }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue';
import { lang } from '@/lang';
//接受三个参数，订单类型，订单信息，订单单号
const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  transactionGoods: {
    type: Object,
    default: () => ({}),
  },
});
const { type, transactionGoods } = toRefs(props);
</script>

<style scoped lang="less">
.points-detailed {
  padding: 0.12rem;
  background: #ffffff;
  border-radius: 0.08rem;
  margin-top: 0.1rem;
  .detailed-top {
    padding-bottom: 0.16rem;
    border-bottom: 0.01rem solid #f6f6f8;
    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 0.16rem;
      line-height: 0.19rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-left {
        .buy {
          color: #dc4343;
        }
        .sell {
          color: #47c754;
        }
        .integral {
          padding-left: 0.04rem;
          color: #222222;
        }
      }
      .title-right {
        .order-id {
          font-weight: 400;
          font-size: 0.12rem;
          color: #858c9f;
          line-height: 0.18rem;
        }
      }
    }
    .total-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.12rem;
      font-weight: 400;
      font-size: 0.14rem;
      line-height: 0.16rem;
      color: #858c9f;
      .number {
        color: #222222;
      }
    }
  }
  .detailed-bottom {
    .tips {
      display: flex;
      align-items: center;
      padding-top: 0.1rem;
      font-weight: 400;
      font-size: 0.12rem;
      color: #858c9f;
      line-height: 0.18rem;
      .tips-icon {
        margin-right: 0.04rem;
        width: 0.14rem;
        height: 0.14rem;
      }
    }
  }
}
</style>
